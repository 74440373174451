import Menu from './Menu'
import Home from './Home'

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <div className='container'>
        <div className='menu-header'>
          <Menu />
        </div>
        <div className='container-app'>
          <Routes>
            <Route exact path="/" element={<Navigate to="/Home" />} />
            <Route path="/Home" element={<Home />} />
          </Routes>
        </div>        
      </div>
    </Router>
  );
}

export default App;