import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faHouseChimney, faCow } from '@fortawesome/free-solid-svg-icons';
import './Menu.css';

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    const currentPath = location.pathname;
    console.log(currentPath)
    setSelectedItem(currentPath === '/'? '/Home': currentPath);
  }, [location.pathname]);

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <div className="menu-facebook">
      <div onClick={() => handleItemClick('/Home')} className={`menu-item ${selectedItem === '/Home' ? 'selected' : ''}`}>
        <FontAwesomeIcon icon={faHouseChimney} size="2x"/>
        <span>Inicio</span>
      </div>

      <div onClick={() => handleItemClick('/ReqRegistries')} className={`menu-item ${selectedItem === '/ReqRegistries' ? 'selected' : ''}`} >
        <FontAwesomeIcon icon={faCow} size='2x'/>
        <span>Sol. Registros</span>
      </div>

      <div onClick={() => handleItemClick('/ReqTransfers')} className={`menu-item ${selectedItem === '/ReqTransfers' ? 'selected' : ''}`}>
        <FontAwesomeIcon icon={faBell} size='2x' />
        <span>Sol. Transferencias</span>
      </div>
    </div>
  );
};

export default Menu;
