import React, { useState, useEffect } from 'react';
import axios from "axios";

const Home = () =>{
    const [farmerData, setFarmerData] = useState([]);
  
    useEffect(() => {      
      axios.get('https://technozoo.azure-api.net/tnz-exp-livestock-app/api/v1/exp/farmers/11522141688319543')
        .then(response => setFarmerData(response.data))
        .catch(error => console.error(error));
    }, []);

    return (
        <div>
            {farmerData.firstName}
        </div>
    );
};

export default Home;